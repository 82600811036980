import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IndividualSignal from './IndividualSignal'
import { useToastContext } from 'src/context/ToastContext'

import { getIndividualFinding } from 'src/actions/openapi'
import camelcaseKeys from 'camelcase-keys'

const IndividualSignalPath = ({ match }) => {
    const [finding, setIndividualFinding] = useState({})
    const { sendToast } = useToastContext()

    const asyncGetIndividualFinding = async () => {
        try {
            const finding = await getIndividualFinding(match.params.findingId)

            // Preprocess close reason
            const processedFinding = {
                ...camelcaseKeys(finding),
                closeReason: (() => {
                    if (!finding.events || finding.events.length === 0) {
                        return 'Reason Not Recorded'
                    }

                    const closeEvents = finding.events.filter(
                        (event) => event.action === 'CLOSE'
                    )
                    const closeEvent =
                        closeEvents.length > 0
                            ? closeEvents.reduce((latest, current) =>
                                  new Date(current.created_date) >
                                  new Date(latest.created_date)
                                      ? current
                                      : latest
                              )
                            : null

                    if (
                        closeEvent?.reason === 'OTHER' &&
                        closeEvent.other_reason
                    ) {
                        return closeEvent.other_reason.replace(/_/g, ' ')
                    }

                    return closeEvent?.reason
                        ? closeEvent.reason.replace(/_/g, ' ')
                        : 'Reason Not Recorded'
                })(),
            }
            setIndividualFinding(processedFinding)
        } catch (err) {
            console.log('asyncGetIndividualFinding', err)
            sendToast(
                `Error getting finding: ${err?.response?.data?.detail}`,
                'error'
            )
        }
    }

    useEffect(() => {
        asyncGetIndividualFinding()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.findingId])
    return (
        <IndividualSignal
            {...finding}
            asyncGetIndividualFinding={asyncGetIndividualFinding}
            onApiResponse={(response) =>
                setIndividualFinding(camelcaseKeys(response))
            }
        />
    )
}

export default IndividualSignalPath

IndividualSignalPath.propTypes = {
    match: PropTypes.object,
}
