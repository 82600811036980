import { useState, Fragment, forwardRef } from 'react'
import {
    Box,
    Divider,
    Button,
    // List,
    // ListItem,
    // ListItemText,
    Modal,
    Paper,
    Typography,
    makeStyles,
    withStyles,
} from '@material-ui/core'
import ReactPlayer from 'react-player'
import { NavLink } from 'react-router-dom'
import CloseModal from './CloseModal'
import { useGlobalState } from 'src/context/useGlobalState'
import { BlackOnWhiteTooltip } from 'src/components/Tooltip'
//import Linkify from 'react-linkify'
import gfm from 'remark-gfm'
import clsx from 'clsx'
import useLoader from 'src/hooks/useLoader'
import './mdSignals.css'
import MarkDown from 'src/components/MarkDown'
import { escalateFinding, closeFinding } from 'src/actions/openapi'
import { useToastContext } from 'src/context/ToastContext'
const useStyles = makeStyles((theme) => {
    return {
        root: {
            // width: '675px',
            // height: `calc(825px + ${theme.spacing(6)}px + ${theme.spacing(
            //     4
            // )}px)`,
            margin: theme.spacing(3),
        },
        link: {
            color: theme.palette.link.main,
            '&:visited': {
                color: theme.palette.link.visited,
                // textDecoration: 'underline',
            },
            '&:hover': {
                color: theme.palette.link.hover,
                textDecoration: 'underline',
            },
        },
        findingDate: {
            marginTop: '5px',
            marginLeft: theme.spacing(2) - 1,
        },
        findingBody: {
            marginTop: theme.spacing(2),
            height: 'calc(100vh - 240px)',
            overflowY: 'auto',
        },
        findingBlock: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        mdBlock: {
            color: theme.palette.text.primary,
        },
        daysLabelContainer: {
            position: 'absolute',
            top: '80px',
            right: '25px',
            height: '28px',
            display: 'flex',
        },
        actionButton: {
            marginRight: '10px',
        },
        CloseSignalModal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        strikethrough: {
            textDecoration: 'line-through',
            color: 'grey',
        },
    }
})

// const ListBlock = ({ heading, listItems, className }) => {
//     return (
//         <Fragment>
//             {listItems && listItems.length > 0 && (
//                 <Paper className={clsx('listFindings', className)}>
//                     <Typography variant="h4" color="textSecondary">
//                         {heading}
//                     </Typography>
//                     <Divider style={{ margin: '10px 0' }} />
//                     <List disablePadding={true}>
//                         {listItems.map((reference, index) => (
//                             <ListItem disableGutters style={{ padding: 0 }}>
//                                 <ListItemText key={`${reference}-${index}`}>
//                                     <Linkify>{reference}</Linkify>
//                                 </ListItemText>
//                             </ListItem>
//                         ))}
//                     </List>
//                 </Paper>
//             )}
//         </Fragment>
//     )
// }

const MDFindingBlock = ({ heading, body, className }) => {
    return (
        <Fragment>
            {body && (
                <Paper className={clsx('mdFindings', className)}>
                    <Typography variant="h4" color="textSecondary">
                        {heading}
                    </Typography>
                    <Divider style={{ margin: '10px 0' }} />
                    {/* <Typography>{ReactMarkdown(body)}</Typography> */}
                    <MarkDown className="customTable" remarkPlugins={[gfm]}>
                        {body.toString()}
                    </MarkDown>
                </Paper>
            )}
        </Fragment>
    )
}

// const FindingBlock = ({ heading, body, className }) => {
//     return (
//         <Fragment>
//             {body && (
//                 <Paper className={className}>
//                     <Typography variant="h4" color="textSecondary">
//                         {heading}
//                     </Typography>
//                     <Divider style={{ margin: '10px 0' }} />
//                     <Typography>{body}</Typography>
//                 </Paper>
//             )}
//         </Fragment>
//     )
// }

const CustomButton = withStyles({
    root: {
        pointerEvents: 'auto',
        paddingBottom: '1px',
    },
})(
    forwardRef((props, ref) => {
        return <Button {...props} ref={ref} />
    })
)

function IndividualFinding({
    active,
    attackSurface,
    affectedAssets,
    count,
    closed,
    createdDate,
    criticality,
    description,
    detection,
    evidence,
    id,
    mitreAttack,
    recommendations,
    references,
    reviewed,
    resources,
    summary,
    title,
    onClose,
    escalated,
    outcome,
    onApiResponse,
    asyncGetIndividualFinding,
    closeReason,
}) {
    const classes = useStyles()
    const [showCloseDialogue, setShowCloseDialogue] = useState(false)
    const [loading, showLoader, hideLoader] = useLoader()
    const { sendToast } = useToastContext()
    const { serviceEnabled } = useGlobalState()

    const asyncEscalateFinding = async (id) => {
        showLoader()
        escalateFinding(id)
            .then((response) => {
                if (response.escalated) {
                    onApiResponse(response)
                    asyncGetIndividualFinding()
                    sendToast('Successfully escalated Signal')
                }
            })
            .catch((err) => {
                console.log('escalateFinding', err)
                sendToast(
                    `Error escalating: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                hideLoader()
            })
    }
    const handleCloseSignal = async (closeReason) => {
        return await closeFinding({ ...closeReason, id })
            .then((response) => {
                if (response.closed) {
                    onApiResponse(response)
                    sendToast('Successfully closed Signal')
                }
            })
            .catch((err) => {
                console.log('closeFinding', err)
                sendToast(
                    `Error closing signal: ${err?.response?.data?.detail}`,
                    'error'
                )
            })
            .finally(() => {
                setShowCloseDialogue(false)
            })
    }

    return (
        <Box className={classes.root}>
            {loading}
            <div className={classes.daysLabelContainer}>
                <CloseModal
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.CloseSignalModal}
                    open={showCloseDialogue}
                    onCloseModal={() => {
                        setShowCloseDialogue(false)
                    }}
                    onCloseSignal={handleCloseSignal}
                />

                {createdDate && !closed ? (
                    !escalated ? (
                        <BlackOnWhiteTooltip
                            interactive={true}
                            title={
                                !serviceEnabled.includes('MDR') ? (
                                    <Fragment>
                                        Ask us about Escalated Signals to
                                        improve your security stance! <br></br>
                                        <a
                                            className={classes.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.uvcyber.com/contact"
                                        >
                                            Contact us for more information or
                                            questions
                                        </a>
                                    </Fragment>
                                ) : (
                                    ''
                                )
                            }
                            followcursor="true"
                        >
                            <div>
                                <CustomButton
                                    className={classes.actionButton}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        asyncEscalateFinding(id)
                                    }}
                                    disabled={!serviceEnabled.includes('MDR')}
                                >
                                    Escalate
                                </CustomButton>
                            </div>
                        </BlackOnWhiteTooltip>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}

                {createdDate ? (
                    !closed ? (
                        <Button
                            className={classes.actionButton}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setShowCloseDialogue(true)
                            }}
                        >
                            Close
                        </Button>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}
            </div>
            <Typography variant="h2" color="textPrimary">
                {title}
            </Typography>

            <Typography
                variant="h5"
                color="textSecondary"
                className={classes.findingDate}
            >
                Discovered {createdDate}
            </Typography>

            {escalated ? (
                <Typography
                    variant="h4"
                    color="error"
                    className={`${classes.findingDate} ${
                        closed ? classes.strikethrough : ''
                    }`}
                >
                    Investigating
                </Typography>
            ) : (
                ''
            )}

            {outcome ? (
                <Typography
                    variant="h4"
                    color="error"
                    className={`${classes.findingDate} ${
                        closed ? classes.strikethrough : ''
                    }`}
                >
                    Escalated to Customer
                </Typography>
            ) : (
                ''
            )}

            {closed ? (
                <Typography
                    variant="h4"
                    color="error"
                    className={classes.findingDate}
                >
                    Closed: {closeReason || 'Reason Not Recorded'}
                </Typography>
            ) : (
                ''
            )}

            <Box className={classes.findingBody}>
                <MDFindingBlock
                    heading={'Description'}
                    body={description}
                    className={classes.findingBlock}
                />
                <MDFindingBlock
                    heading={'Summary'}
                    body={summary}
                    className={classes.findingBlock}
                />
                <MDFindingBlock
                    heading={'Affected Assets'}
                    body={affectedAssets}
                    className={clsx(classes.findingBlock, classes.mdBlock)}
                />
                {/* <ListBlock
                    className={classes.findingBlock}
                    heading={'Affected Assets'}
                    listItems={affectedAssets}
                /> */}
                <MDFindingBlock
                    heading={'Evidence'}
                    body={evidence}
                    className={clsx(classes.findingBlock, classes.mdBlock)}
                />
                <MDFindingBlock
                    heading={'Detection'}
                    body={detection}
                    className={classes.findingBlock}
                />
                {resources && resources.length > 0 && (
                    <Paper className={classes.findingBlock}>
                        <Typography variant="h4" color="textSecondary">
                            Resources
                        </Typography>
                        <Divider style={{ margin: '10px 0' }} />
                        <Box display="flex" justifyContent="space-between">
                            {resources.map((video) => (
                                <ReactPlayer controls url={video} width="33%" />
                            ))}
                        </Box>
                    </Paper>
                )}
                {/* <FindingBlock
                    heading='Discovered'
                    body={createdDate}
                    className={classes.findingBlock}
                /> */}
                <MDFindingBlock
                    heading={'Recommendations'}
                    body={recommendations}
                    className={clsx(classes.findingBlock, classes.mdBlock)}
                />
                <MDFindingBlock
                    className={clsx(classes.findingBlock, classes.mdBlock)}
                    heading={'References'}
                    body={references}
                />
            </Box>
        </Box>
    )
}

IndividualFinding.defaultProps = {
    references: [],
    resources: [],
    closeReason: 'Reason Not Recorded',
}

export default IndividualFinding
